/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Mono_193799';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/672d585acd0ee9fd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Mono_193799';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6e0a0441ad79e06b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Mono_193799';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/074264520bdb2a63-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Mono_193799';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d8beb6bbe6650ea1-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Mono_193799';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d3ba4ac8f7146535-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Mono_193799';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/498cd24af98ee1c5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Mono_Fallback_193799';src: local("Arial");ascent-override: 68.79%;descent-override: 19.50%;line-gap-override: 0.00%;size-adjust: 135.93%
}.__className_193799 {font-family: '__Fira_Mono_193799', '__Fira_Mono_Fallback_193799', Menlo, Monaco, monospace, Consolas, Courier New, monospace;font-weight: 500;font-style: normal
}

